import Swup from 'swup';

const swup = new Swup({
    containers: ['#swupMain', '#swupTopbar'],
    animateHistoryBrowsing: true,
    linkSelector: 'a:not([data-no-swup])',
    animationSelector: '[class="mil-main-transition"]'
});

function initalizeSlider(re=false) {
    if (re) jQuery("#slider2").empty();

    jQuery("#slider2").slideToUnlock({
        useData: true,
        theme: "grey",
        unlockfn: function(){
            jQuery('#sendInquiry, #sendCreateCourse').attr('disabled', false);
            jQuery('[name="'+jQuery("#slider2").data('input')+'"]').val(jQuery("#slider2").data('key'));
        },
    });
}

swup.hooks.on('page:view', () => {
    window.dataLayer.push({
        event: 'VirtualPageview',
        virtualPageURL: window.location.pathname + window.location.search,
        virtualPageTitle: document.title
    });
});

function toggleRodo() {
    jQuery('#rodo-toggle').on('click', function() {
        jQuery(this).text(function(i, text) {
            return text === "więcej" ? "mniej" : "więcej";
        });
        jQuery('#rodo-more-info').fadeToggle();
        ScrollTrigger.refresh();
    });
}

toggleRodo();

function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(String(email).toLowerCase());
}

function sprawdz() {
    jQuery('#rentInnquiry').find('input[type="text"], input[type="email"], textarea').each(function() {
        if( jQuery(this).attr("type") === "email" ) {
            jQuery(this).on('input', function() {
                if (isValidEmail(jQuery(this).val())) {
                    jQuery(jQuery(this)).next('.validation-msg').text('').css('opacity', 0);
                } else {
                    jQuery(jQuery(this)).next('.validation-msg').text('Adres email jest nieprawidłowy').css('opacity', 1);
                }
            });
        } else {
            jQuery(this).on('input', function() {
                if(jQuery(this).val() === '') {
                    jQuery(this).attr('invalid', 'true');
                    jQuery(this).next('.validation-msg').text('To pole jest wymagane').css('opacity', 1);
                } else {
                    jQuery(this).attr('invalid', 'false');
                    jQuery(this).next('.validation-msg').text('').css('opacity', 0);
                }
            });
            
        }
    });
}

function checkAcceptance() {
    let rodo_accepted = jQuery('#acceptance').prop('checked');

    if (rodo_accepted != true) {
        jQuery('.validation-check-msg').css("opacity", 1).text('To pole jest wymagane');

        return;
    }
}

jQuery('#acceptance').on('change', function() {
    if(jQuery(this).prop('checked')) {
        jQuery('.validation-check-msg').css("opacity", 0).text('');
    } else {
        jQuery('.validation-check-msg').css("opacity", 1).text('To pole jest wymagane');
    }
});

function sendMsgForm() {
    jQuery('#sendInquiry').click(function (e) {
        e.preventDefault();

        var form_action = jQuery(this).data('action');

        jQuery('#rentInnquiry').find('input[type="text"], input[type="email"], textarea').each(function() {
            if(jQuery(this).val() === '') {
                jQuery(this).attr('invalid', 'true');
                jQuery(this).next('.validation-msg').text('To pole jest wymagane').css('opacity', 1);
            } else {
                jQuery(this).attr('invalid', 'false');
                jQuery(this).next('.validation-msg').text('').css('opacity', 0);
            }
        });

        const email = jQuery('#rentInnquiry').find('input[type="email"]');

        if (isValidEmail(email.val())) {
            jQuery(email).next().text('');
        } else {
            jQuery(email).next().text('Adres email jest nieprawidłowy');
        }

        let $validate = jQuery('#rentInnquiry').find('[invalid="true"]').not('input:disabled');

        if ($validate.length) {
            return;
        }

        checkAcceptance();

        if (jQuery('[name="key"]').val() == '') {
            return;
        }

        let mail_data = jQuery('#rentInnquiry').serializeArray();
    
        jQuery('.loader').fadeIn();
        jQuery('input').attr('validity', '');
        jQuery('.rent-form-price').addClass('d-none');
        jQuery.ajax({
            url: ajaxurl,
            data:{
                action: form_action,
                mail_data: mail_data,
            },
            dataType : 'json',
            type:'POST',
            success: function( data ) {
                jQuery('.loader').fadeOut();
                if (data.hasOwnProperty('result') && data.result == "success") {
                    jQuery('.response-output').text(data.msg).css({
                        'opacity': 1,
                        'padding': '20px',
                        'margin': '50px auto 0',
                        'visibility': 'visible'
                    });

                    jQuery('#rentInnquiry').find('input:not(:radio):not([type=hidden]), textarea').val('').removeClass('mil-keep');
                    jQuery('#acceptance').prop('checked', false);

                    initalizeSlider(true);
                } else {
                    if(data.type === 'email') {
                        jQuery('input[type="email"]').next('.validation-msg').text(data.msg).css('opacity', 1);
                    }
                }
            }
        });
    });
}

sendMsgForm();
sprawdz();
initalizeSlider();

/***************************

color variables

***************************/

var accent = '#FFA726';
var dark = '#263238';
var light = '#FAFAFA';

/***************************

register gsap plugins

***************************/
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);
/***************************

smooth scroll

***************************/
ScrollSmoother.create({
    smooth: 1,
    effects: true,
    smoothTouch: 0.1,
});
//ScrollTrigger.normalizeScroll(true);
/***************************

preloader

***************************/

const preloaderTimeline = gsap.timeline();

preloaderTimeline
    .to(".mil-preloader-content", {
        ease: "sine",
        duration: 0.4,
        scale: 1,
        opacity: 1,
        delay: '0.4',
    })
    .to(".mil-ticker", {
        x: '-50%',
        ease: "linear",
        duration: 6,
        delay: '-0.4',
    })
    .to(".mil-preloader-load ", {
        width: '100%',
        ease: "sine",
        duration: 1.5,
        delay: '-5.5',
    })
    .to(".mil-preloader-content ", {
        scale: .9,
        opacity: 0,
        ease: "sine",
        duration: 0.4,
        delay: '-4',
        onComplete: function () {
            ScrollTrigger.refresh();
        },
    })
    .to(".mil-preloader-load-track ", {
        opacity: 0,
        ease: "sine",
        duration: 1.5,
        delay: '-4',
    })
    .to(".mil-preloader-1", {
        height: 0,
        ease: "sine",
        duration: 0.4,
        delay: '-3.5',
    });

/***************************

back to top

***************************/
jQuery(".mil-circle").on("click", function () {
    gsap.to(window, {
        scrollTo: '0',
        duration: 1,
        ease: 'sine',
    });
});
/***************************

cursor

***************************/

//follower

const cursor = document.querySelector('.mil-cursor');
const cursorWord1 = document.querySelector('.mil-cursor .mil-word-1');
const cursorWord2 = document.querySelector('.mil-cursor .mil-word-2');
const cursorIcon1 = document.querySelector('.mil-cursor .mil-icon-1');
const cursorIcon2 = document.querySelector('.mil-cursor .mil-icon-2');
const cursorIcon3 = document.querySelector('.mil-cursor .mil-icon-3');

gsap.set(cursor, {
    xPercent: -50,
    yPercent: -50,
});

document.addEventListener('pointermove', movecursor);

function movecursor(e) {
    gsap.to(cursor, {
        duration: 0.6,
        ease: 'sine',
        x: e.clientX,
        y: e.clientY,
    });
}

//click and drag

jQuery('body').mousedown(function () {
    gsap.to(jQuery(cursor), .2, {
        scale: .7,
        boxShadow: '0px 0px 0px 10px rgba(255,255,255,.4)',
        ease: 'sine',
    });
});
jQuery('body').mouseup(function () {
    gsap.to(jQuery(cursor), .2, {
        scale: 1,
        boxShadow: '0px 0px 0px 5px rgba(255,255,255,0)',
        ease: 'sine',
    });
});

//accent color

jQuery('.mil-accent-trigger').mouseover(function () {
    gsap.to(jQuery(cursor), .2, {
        background: accent,
        ease: 'sine',
    });
    jQuery(cursor).addClass('mil-dark');
});

jQuery('.mil-accent-trigger').mouseleave(function () {
    gsap.to(jQuery(cursor), .2, {
        background: dark,
        ease: 'sine',
    });
    jQuery(cursor).removeClass('mil-dark');
});

//light color

jQuery('.mil-light-trigger').mouseover(function () {
    gsap.to(jQuery(cursor), .2, {
        background: light,
        ease: 'sine',
    });
    jQuery(cursor).addClass('mil-dark');
});

jQuery('.mil-light-trigger').mouseleave(function () {
    gsap.to(jQuery(cursor), .2, {
        background: dark,
        ease: 'sine',
    });
    jQuery(cursor).removeClass('mil-dark');
});

//hidden

jQuery('.mil-hidden-trigger').mouseover(function () {
    gsap.to(jQuery(cursor), .2, {
        width: 0,
        height: 0,
        ease: 'sine',
    });
});

jQuery('.mil-hidden-trigger').mouseleave(function () {
    gsap.to(jQuery(cursor), .2, {
        width: 25,
        height: 25,
        ease: 'sine',
    });
});

//scale down

jQuery('.mil-scale-down-trigger').mouseover(function () {
    gsap.to(jQuery(cursor), .2, {
        opacity: 1,
        width: 10,
        height: 10,
        ease: 'sine',
    });
});

jQuery('.mil-scale-down-trigger').mouseleave(function () {
    gsap.to(jQuery(cursor), .2, {
        opacity: .1,
        width: 25,
        height: 25,
        ease: 'sine',
    });
});

//scale up

jQuery('.mil-scale-up-trigger').mouseover(function () {
    gsap.to(jQuery(cursor), .2, {
        width: 75,
        height: 75,
        ease: 'sine',
    });
});

jQuery('.mil-scale-up-trigger').mouseleave(function () {
    gsap.to(jQuery(cursor), .2, {
        width: 25,
        height: 25,
        ease: 'sine',
    });
});

//scale up with word and icons

jQuery('.mil-word-1-trigger , .mil-word-2-trigger , .mil-icon-1-trigger, .mil-icon-2-trigger, .mil-icon-3-trigger').mouseover(function () {
    gsap.to(jQuery(cursor), .2, {
        opacity: 1,
        width: 75,
        height: 75,
        ease: 'sine',
    });
});

jQuery('.mil-word-1-trigger , .mil-word-2-trigger , .mil-icon-1-trigger, .mil-icon-2-trigger, .mil-icon-3-trigger').mouseleave(function () {
    gsap.to(jQuery(cursor), .2, {
        opacity: .1,
        width: 25,
        height: 25,
        ease: 'sine',
    });
});

//word 1

jQuery('.mil-word-1-trigger').mouseover(function () {
    gsap.to(jQuery(cursorWord1), .2, {
        opacity: 1,
        scale: 1,
        ease: 'sine',
    });
});

jQuery('.mil-word-1-trigger').mouseleave(function () {
    gsap.to(jQuery(cursorWord1), .2, {
        opacity: 0,
        scale: .6,
        ease: 'sine',
    });
});

//word 2

jQuery('.mil-word-2-trigger').mouseover(function () {
    gsap.to(jQuery(cursorWord2), .2, {
        opacity: 1,
        scale: 1,
        ease: 'sine',
    });
});

jQuery('.mil-word-2-trigger').mouseleave(function () {
    gsap.to(jQuery(cursorWord2), .2, {
        opacity: 0,
        scale: .6,
        ease: 'sine',
    });
});

//icon 1

jQuery('.mil-icon-1-trigger').mouseover(function () {
    gsap.to(jQuery(cursorIcon1), .2, {
        opacity: 1,
        scale: 1,
        ease: 'sine',
    });
});

jQuery('.mil-icon-1-trigger').mouseleave(function () {
    gsap.to(jQuery(cursorIcon1), .2, {
        opacity: 0,
        scale: .6,
        ease: 'sine',
    });
});

//icon 2

jQuery('.mil-icon-2-trigger').mouseover(function () {
    gsap.to(jQuery(cursorIcon2), .2, {
        opacity: 1,
        scale: 1,
        ease: 'sine',
    });
});

jQuery('.mil-icon-2-trigger').mouseleave(function () {
    gsap.to(jQuery(cursorIcon2), .2, {
        opacity: 0,
        scale: .6,
        ease: 'sine',
    });
});

//icon 3

jQuery('.mil-icon-3-trigger').mouseover(function () {
    gsap.to(jQuery(cursorIcon3), .2, {
        opacity: 1,
        scale: 1,
        ease: 'sine',
    });
});

jQuery('.mil-icon-3-trigger').mouseleave(function () {
    gsap.to(jQuery(cursorIcon3), .2, {
        opacity: 0,
        scale: .6,
        ease: 'sine',
    });
});
/***************************

scroll animations

***************************/

//appearance

const appearance = document.querySelectorAll(".mil-appearance");

appearance.forEach((section) => {
    gsap.fromTo(section, {
        opacity: 0,
        y: 50,
        scale: .98,
        ease: 'sine',
    }, {
        y: 0,
        opacity: 1,
        scale: 1,
        scrollTrigger: {
            trigger: section,
            toggleActions: 'play none none reverse',
        }
    });
});

//scale

const scaleImage = document.querySelectorAll(".mil-scale-img");

scaleImage.forEach((section) => {
    var value1 = jQuery(section).data("value-1");
    var value2 = jQuery(section).data("value-2");
    gsap.fromTo(section, {
        ease: 'sine',
        scale: value1,

    }, {
        scale: value2,
        scrollTrigger: {
            trigger: section,
            scrub: true,
            toggleActions: 'play none none reverse',
        }
    });
});

//parallax

const parallaxImage = document.querySelectorAll(".mil-parallax-img");

parallaxImage.forEach((section) => {
    var value1 = jQuery(section).data("value-1");
    var value2 = jQuery(section).data("value-2");
    gsap.fromTo(section, {
        ease: 'sine',
        y: value1,

    }, {
        y: value2,
        scrollTrigger: {
            trigger: section,
            scrub: true,
            toggleActions: 'play none none reverse',
        }
    });
});

//rotate

const rotate = document.querySelectorAll(".mil-rotate");

rotate.forEach((section) => {
    var value = jQuery(section).data("value");
    gsap.fromTo(section, {
        ease: 'sine',
        rotate: 0,

    }, {
        rotate: value,
        scrollTrigger: {
            trigger: section,
            scrub: true,
            toggleActions: 'play none none reverse',
        }
    });
});

//travel x

const travelX = document.querySelectorAll(".mil-travel-x");

travelX.forEach((section) => {
    var value = jQuery(section).data("value");
    gsap.fromTo(section, {
        ease: 'sine',
        x: 0,

    }, {
        x: value,
        scrollTrigger: {
            trigger: section,
            scrub: true,
            toggleActions: 'play none none reverse',
        }
    });
});

//travel y

const travelY = document.querySelectorAll(".mil-travel-y");

travelY.forEach((section) => {
    var value = jQuery(section).data("value");
    gsap.fromTo(section, {
        ease: 'sine',
        y: 0,

    }, {
        y: value,
        scrollTrigger: {
            trigger: section,
            scrub: true,
            toggleActions: 'play none none reverse',
        }
    });
});

//dissolve

const dissolve = document.querySelectorAll(".mil-dissolve");

dissolve.forEach((section) => {
    gsap.fromTo(section, {
        opacity: 1,
        ease: 'linear',

    }, {
        opacity: 0,
        scrollTrigger: {
            trigger: section,
            scrub: true,
            start: 'top-=600',
            end: 'bottom-=100',
        }
    });
});

/***************************

progressbar

***************************/
gsap.to('.mil-progress', {
    height: '100%',
    ease: 'sine',
    scrollTrigger: {
        scrub: 0.3
    }
});
/***************************

counters

***************************/
const number = jQuery(".mil-counter");
number.each(function (index, element) {
    var count = jQuery(this),
        zero = {
            val: 0
        },
        num = count.data("number"),
        split = (num + "").split("."), // to cover for instances of decimals
        decimals = split.length > 1 ? split[1].length : 0;

    gsap.to(zero, {
        val: num,
        duration: 2,
        scrollTrigger: {
            trigger: element,
            toggleActions: 'play none none reverse',
        },
        onUpdate: function () {
            count.text(zero.val.toFixed(decimals));
        }
    });
});
/***************************

navigation

***************************/
const showAnim = gsap.from('.mil-top-panel.mil-animated', {
    yPercent: -100,
    paused: true,
    duration: 0.4,
}).progress(1);

ScrollTrigger.create({
    start: "top top",
    end: 99999999,
    onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
    }
});

const showColor = document.querySelectorAll(".mil-top-panel.mil-transparent-nav");

showColor.forEach((section) => {
    gsap.fromTo(
        section, {
            ease: 'sine',
            backgroundColor: 'rgba(38, 50, 56, 0)',
        }, {
            backgroundColor: dark,
            scrollTrigger: {
                start: "top -100, top",
                end: 99999,
                toggleClass: 'active',
            }
        }
    );
});

jQuery(document).on('click', '.mil-menu-btn', function () {
    jQuery(this).toggleClass('mil-active');
    jQuery('.mil-mobile-dropdown').toggleClass('mil-active');
});

jQuery(document).on('click', function (e) {
    const el = '.mil-top-panel';
    if (jQuery(e.target).closest(el).length) return;
    jQuery('.mil-menu-btn').removeClass('mil-active');
    jQuery('.mil-mobile-dropdown').removeClass('mil-active');
});
/***************************

pseudo hover

***************************/
jQuery('.mil-pseudo-hover').addClass('mil-active');
jQuery('.mil-services-1').on('mouseover', function () {
    jQuery('.mil-pseudo-hover').removeClass('mil-active');
}).on('mouseout', function () {
    jQuery('.mil-pseudo-hover').addClass('mil-active');
});
/***************************

fields

***************************/
jQuery('input, textarea').on('blur', function () {
    var $this = jQuery(this);
    if ($this.val() !== "") {
        $this.addClass('mil-keep');
    } else {
        $this.removeClass('mil-keep');
    }
});
/***************************

slider

***************************/
var swiper = new Swiper('.mil-slider', {
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 450,
    touchStartPreventDefault: false,
    longSwipes: true,
    breakpoints: {
        992: {
            slidesPerView: 3,
        },
    },
});
/***************************

infinite slider

***************************/
var swiper = new Swiper('.mil-infinite-show', {
    slidesPerView: 2,
    spaceBetween: 0,
    speed: 3000,
    autoplay: true,
    autoplay: {
        delay: 0,
    },
    loop: true,
    freeMode: true,
    breakpoints: {
        992: {
            slidesPerView: 4,
        },
    },
});

/***************************

instagram slider

***************************/
var swiper = new Swiper('.mil-instagram-slider', {
    slidesPerView: 2,
    spaceBetween: 0,
    speed: 3000,
    autoplay: true,
    autoplay: {
        delay: 0,
    },
    loop: true,
    freeMode: true,
    breakpoints: {
        992: {
            slidesPerView: 6,
        },
        768: {
            slidesPerView: 3,
        },
    },
});
/***************************

about slider

***************************/
var swiper = new Swiper('.mil-about-slider', {
    slidesPerView: 2,
    spaceBetween: 30,
    speed: 450,
    initialSlide: 1,
    touchStartPreventDefault: false,
    longSwipes: true,
    navigation: {
        prevEl: '.mil-about-prev',
        nextEl: '.mil-about-next',
    },
    breakpoints: {
        992: {
            spaceBetween: 30,
            parallax: false,
        },
    },
});
/***************************

gallery slider

***************************/
var swiper = new Swiper('.mil-gallery-slider', {
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 450,
    slidesPerView: "auto",
    touchStartPreventDefault: false,
    longSwipes: true,
    breakpoints: {
        992: {
            slidesPerView: 2,
        },
    },
});
/***************************

fancybox

***************************/
if (jQuery('[data-fancybox]').length > 0) {
    jQuery('[data-fancybox]').fancybox({
        buttons: ["slideShow",
            "close"
        ],
        parentEl: "body",
        protect: true,
        loop: false,
        animationEffect: "slide-in-out",
        animationDuration: 600,
        transitionEffect: "fade-in-out",
        transitionDuration: 600,
    });

    jQuery('[data-fancybox="video"]').fancybox({
        buttons: ["slideShow",
            "close"
        ],
        loop: false,
        animationEffect: "slide-in-out",
        animationDuration: 600,
        transitionEffect: "fade-in-out",
        transitionDuration: 600,
    });

    jQuery.fancybox.defaults.hash = false;
}

/*----------------------------------------------------------
------------------------------------------------------------

REINIT

------------------------------------------------------------
----------------------------------------------------------*/
swup.hooks.on('content:replace', () => {
    sendMsgForm();
    sprawdz();
    isValidEmail();
    toggleRodo();
    initalizeSlider();

    /***************************

    mobile menu

    ***************************/
    jQuery('.mil-menu-btn').removeClass('mil-active');
    jQuery('.mil-mobile-dropdown').removeClass('mil-active');
    /***************************

    register gsap plugins

    ***************************/
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);
    /***************************

    smooth scroll

    ***************************/
    ScrollSmoother.create({
        smooth: 1,
        effects: true,
        smoothTouch: 0.1,
    });
    //ScrollTrigger.normalizeScroll(true);
    /***************************

    cursor

    ***************************/

    //accent color

    jQuery('.mil-accent-trigger').mouseover(function () {
        gsap.to(jQuery(cursor), .2, {
            background: accent,
            ease: 'sine',
        });
        jQuery(cursor).addClass('mil-dark');
    });

    jQuery('.mil-accent-trigger').mouseleave(function () {
        gsap.to(jQuery(cursor), .2, {
            background: dark,
            ease: 'sine',
        });
        jQuery(cursor).removeClass('mil-dark');
    });

    //light color

    jQuery('.mil-light-trigger').mouseover(function () {
        gsap.to(jQuery(cursor), .2, {
            background: light,
            ease: 'sine',
        });
        jQuery(cursor).addClass('mil-dark');
    });

    jQuery('.mil-light-trigger').mouseleave(function () {
        gsap.to(jQuery(cursor), .2, {
            background: dark,
            ease: 'sine',
        });
        jQuery(cursor).removeClass('mil-dark');
    });

    //hidden

    jQuery('.mil-hidden-trigger').mouseover(function () {
        gsap.to(jQuery(cursor), .2, {
            width: 0,
            height: 0,
            ease: 'sine',
        });
    });

    jQuery('.mil-hidden-trigger').mouseleave(function () {
        gsap.to(jQuery(cursor), .2, {
            width: 25,
            height: 25,
            ease: 'sine',
        });
    });

    //scale down

    jQuery('.mil-scale-down-trigger').mouseover(function () {
        gsap.to(jQuery(cursor), .2, {
            opacity: 1,
            width: 10,
            height: 10,
            ease: 'sine',
        });
    });

    jQuery('.mil-scale-down-trigger').mouseleave(function () {
        gsap.to(jQuery(cursor), .2, {
            opacity: .1,
            width: 25,
            height: 25,
            ease: 'sine',
        });
    });

    //scale up

    jQuery('.mil-scale-up-trigger').mouseover(function () {
        gsap.to(jQuery(cursor), .2, {
            width: 75,
            height: 75,
            ease: 'sine',
        });
    });

    jQuery('.mil-scale-up-trigger').mouseleave(function () {
        gsap.to(jQuery(cursor), .2, {
            width: 25,
            height: 25,
            ease: 'sine',
        });
    });

    //scale up with word and icons

    jQuery('.mil-word-1-trigger , .mil-word-2-trigger , .mil-icon-1-trigger, .mil-icon-2-trigger, .mil-icon-3-trigger').mouseover(function () {
        gsap.to(jQuery(cursor), .2, {
            opacity: 1,
            width: 75,
            height: 75,
            ease: 'sine',
        });
    });

    jQuery('.mil-word-1-trigger , .mil-word-2-trigger , .mil-icon-1-trigger, .mil-icon-2-trigger, .mil-icon-3-trigger').mouseleave(function () {
        gsap.to(jQuery(cursor), .2, {
            opacity: .1,
            width: 25,
            height: 25,
            ease: 'sine',
        });
    });

    //word 1

    jQuery('.mil-word-1-trigger').mouseover(function () {
        gsap.to(jQuery(cursorWord1), .2, {
            opacity: 1,
            scale: 1,
            ease: 'sine',
        });
    });

    jQuery('.mil-word-1-trigger').mouseleave(function () {
        gsap.to(jQuery(cursorWord1), .2, {
            opacity: 0,
            scale: .6,
            ease: 'sine',
        });
    });

    //word 2

    jQuery('.mil-word-2-trigger').mouseover(function () {
        gsap.to(jQuery(cursorWord2), .2, {
            opacity: 1,
            scale: 1,
            ease: 'sine',
        });
    });

    jQuery('.mil-word-2-trigger').mouseleave(function () {
        gsap.to(jQuery(cursorWord2), .2, {
            opacity: 0,
            scale: .6,
            ease: 'sine',
        });
    });

    //icon 1

    jQuery('.mil-icon-1-trigger').mouseover(function () {
        gsap.to(jQuery(cursorIcon1), .2, {
            opacity: 1,
            scale: 1,
            ease: 'sine',
        });
    });

    jQuery('.mil-icon-1-trigger').mouseleave(function () {
        gsap.to(jQuery(cursorIcon1), .2, {
            opacity: 0,
            scale: .6,
            ease: 'sine',
        });
    });

    //icon 2

    jQuery('.mil-icon-2-trigger').mouseover(function () {
        gsap.to(jQuery(cursorIcon2), .2, {
            opacity: 1,
            scale: 1,
            ease: 'sine',
        });
    });

    jQuery('.mil-icon-2-trigger').mouseleave(function () {
        gsap.to(jQuery(cursorIcon2), .2, {
            opacity: 0,
            scale: .6,
            ease: 'sine',
        });
    });

    //icon 3

    jQuery('.mil-icon-3-trigger').mouseover(function () {
        gsap.to(jQuery(cursorIcon3), .2, {
            opacity: 1,
            scale: 1,
            ease: 'sine',
        });
    });

    jQuery('.mil-icon-3-trigger').mouseleave(function () {
        gsap.to(jQuery(cursorIcon3), .2, {
            opacity: 0,
            scale: .6,
            ease: 'sine',
        });
    });
    /***************************

    scroll animations

    ***************************/

    //appearance

    const appearance = document.querySelectorAll(".mil-appearance");

    appearance.forEach((section) => {
        gsap.fromTo(section, {
            opacity: 0,
            y: 60,
            scale: .98,
            ease: 'sine',
        }, {
            y: 0,
            opacity: 1,
            scale: 1,
            y: 0,
            scrollTrigger: {
                trigger: section,
                toggleActions: 'play none none reverse',
            }
        });
    });

    //scale

    const scaleImage = document.querySelectorAll(".mil-scale-img");

    scaleImage.forEach((section) => {
        var value1 = jQuery(section).data("value-1");
        var value2 = jQuery(section).data("value-2");
        gsap.fromTo(section, {
            ease: 'sine',
            scale: value1,

        }, {
            scale: value2,
            scrollTrigger: {
                trigger: section,
                scrub: true,
                toggleActions: 'play none none reverse',
            }
        });
    });

    //parallax

    const parallaxImage = document.querySelectorAll(".mil-parallax-img");

    parallaxImage.forEach((section) => {
        var value1 = jQuery(section).data("value-1");
        var value2 = jQuery(section).data("value-2");
        gsap.fromTo(section, {
            ease: 'sine',
            y: value1,

        }, {
            y: value2,
            scrollTrigger: {
                trigger: section,
                scrub: true,
                toggleActions: 'play none none reverse',
            }
        });
    });

    //rotate

    const rotate = document.querySelectorAll(".mil-rotate");

    rotate.forEach((section) => {
        var value = jQuery(section).data("value");
        gsap.fromTo(section, {
            ease: 'sine',
            rotate: 0,

        }, {
            rotate: value,
            scrollTrigger: {
                trigger: section,
                scrub: true,
                toggleActions: 'play none none reverse',
            }
        });
    });

    //travel x

    const travelX = document.querySelectorAll(".mil-travel-x");

    travelX.forEach((section) => {
        var value = jQuery(section).data("value");
        gsap.fromTo(section, {
            ease: 'sine',
            x: 0,

        }, {
            x: value,
            scrollTrigger: {
                trigger: section,
                scrub: true,
                toggleActions: 'play none none reverse',
            }
        });
    });

    //travel y

    const travelY = document.querySelectorAll(".mil-travel-y");

    travelY.forEach((section) => {
        var value = jQuery(section).data("value");
        gsap.fromTo(section, {
            ease: 'sine',
            y: 0,

        }, {
            y: value,
            scrollTrigger: {
                trigger: section,
                scrub: true,
                toggleActions: 'play none none reverse',
            }
        });
    });

    //dissolve

    const dissolve = document.querySelectorAll(".mil-dissolve");

    dissolve.forEach((section) => {
        gsap.fromTo(section, {
            opacity: 1,
            ease: 'linear',

        }, {
            opacity: 0,
            scrollTrigger: {
                trigger: section,
                scrub: true,
                start: 'top-=600',
                end: 'bottom-=100',
            }
        });
    });

    /***************************

    progressbar

    ***************************/
    gsap.to('.mil-progress', {
        height: '100%',
        ease: 'sine',
        scrollTrigger: {
            scrub: 0.3
        }
    });
    /***************************

    counters

    ***************************/
    const number = jQuery(".mil-counter");
    number.each(function (index, element) {
        var count = jQuery(this),
            zero = {
                val: 0
            },
            num = count.data("number"),
            split = (num + "").split("."), // to cover for instances of decimals
            decimals = split.length > 1 ? split[1].length : 0;

        gsap.to(zero, {
            val: num,
            duration: 2,
            scrollTrigger: {
                trigger: element,
                toggleActions: 'play none none reverse',
            },
            onUpdate: function () {
                count.text(zero.val.toFixed(decimals));
            }
        });
    });
    /***************************

    navigation

    ***************************/
    const showAnim = gsap.from('.mil-top-panel.mil-animated', {
        yPercent: -100,
        paused: true,
        duration: 0.4,
    }).progress(1);

    ScrollTrigger.create({
        start: "top top",
        end: 99999999,
        onUpdate: (self) => {
            self.direction === -1 ? showAnim.play() : showAnim.reverse();
        }
    });

    const showColor = document.querySelectorAll(".mil-top-panel.mil-transparent-nav");

    showColor.forEach((section) => {
        gsap.fromTo(
            section, {
                ease: 'sine',
                backgroundColor: 'rgba(38, 50, 56, 0)',
            }, {
                backgroundColor: dark,
                scrollTrigger: {
                    start: "top -100, top",
                    end: 99999,
                    toggleClass: 'active',
                }
            }
        );
    });

    jQuery(document).on('click', function (e) {
        const el = '.mil-top-panel';
        if (jQuery(e.target).closest(el).length) return;
        jQuery('.mil-menu-btn').removeClass('mil-active');
        jQuery('.mil-mobile-dropdown').removeClass('mil-active');
    });
    /***************************

    pseudo hover

    ***************************/

    jQuery('.mil-pseudo-hover').addClass('mil-active');
    jQuery('.mil-pseudo-hover-el').on('mouseover', function () {
        jQuery('.mil-pseudo-hover').removeClass('mil-active');
    }).on('mouseout', function () {
        jQuery('.mil-pseudo-hover').addClass('mil-active');
    });
    /***************************

    fields

    ***************************/
    jQuery('input').on('blur', function () {
        var $this = jQuery(this);
        if ($this.val() !== "") {
            $this.addClass('mil-keep');
        } else {
            $this.removeClass('mil-keep');
        }
    });
    /***************************

    slider

    ***************************/
    var swiper = new Swiper('.mil-slider', {
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 450,
        touchStartPreventDefault: false,
        longSwipes: true,
        breakpoints: {
            992: {
                spaceBetween: 30,
                parallax: false,
            },
        },
    });

    /***************************

    infinite slider

    ***************************/
    var swiper = new Swiper('.mil-infinite-show', {
        slidesPerView: 2,
        spaceBetween: 0,
        speed: 3000,
        autoplay: true,
        autoplay: {
            delay: 0,
        },
        loop: true,
        freeMode: true,
        breakpoints: {
            992: {
                slidesPerView: 4,
            },
        },
    });

    /***************************

    instagram slider

    ***************************/
    var swiper = new Swiper('.mil-instagram-slider', {
        slidesPerView: 6,
        spaceBetween: 0,
        speed: 3000,
        autoplay: true,
        autoplay: {
            delay: 0,
        },
        loop: true,
        freeMode: true,
    });
    /***************************

    about slider

    ***************************/
    var swiper = new Swiper('.mil-about-slider', {
        slidesPerView: 2,
        spaceBetween: 30,
        speed: 450,
        initialSlide: 1,
        touchStartPreventDefault: false,
        longSwipes: true,
        navigation: {
            prevEl: '.mil-about-prev',
            nextEl: '.mil-about-next',
        },
        breakpoints: {
            992: {
                spaceBetween: 30,
                parallax: false,
            },
        },
    });
    /***************************

    portfolio slider

    ***************************/
    var swiper = new Swiper('.mil-portfolio-slider', {
        slidesPerView: 2,
        spaceBetween: 30,
        speed: 450,
        slidesPerView: "auto",
        touchStartPreventDefault: false,
        longSwipes: true,
        navigation: {
            prevEl: '.mil-about-prev',
            nextEl: '.mil-about-next',
        },
        breakpoints: {
            992: {
                spaceBetween: 30,
                parallax: false,
            },
        },
    });
    /***************************

    gallery slider

    ***************************/
    var swiper = new Swiper('.mil-gallery-slider', {
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 450,
        slidesPerView: "auto",
        touchStartPreventDefault: false,
        longSwipes: true,
        breakpoints: {
            992: {
                slidesPerView: 2,
            },
        },
    });
    /***************************

    fancybox

    ***************************/
    jQuery('[data-fancybox]').fancybox({
        buttons: ["slideShow",
        "close"
    ],
        loop: false,
        animationEffect: "slide-in-out",
        animationDuration: 600,
        transitionEffect: "fade-in-out",
        transitionDuration: 600,
    });

    jQuery.fancybox.defaults.hash = false;

});